/*----------  Bootstrap  ----------*/
@import "../vendors/bootstrap/scss/bootstrap";

/*----------  Functions  ----------*/
@import "core/functions";

/*----------  Variables  ----------*/
@import "utilities/variables";

/*----------  Arrays  ----------*/
@import "utilities/arrays";

/*----------  Core  ----------*/
@import "core/mixins";
@import "core/components";

/*----------  Utilities  ----------*/
@import "utilities/global-contextuals";
@import "utilities/reset";

/*----------  Mixins  ----------*/
@import "mixins/typography";

/*----------  Helpers  ----------*/
@import "helpers/iterations";

/*----------  Components  ----------*/
@import "components/commons";
@import "components/header";
@import "components/footer";
@import "components/typography";

/*----------  Views  ----------*/
@import "views/index";


html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  max-width: 100%;
}

a {
  color: #459CFF;

  &:hover {
    color: darken(#459CFF, 10%);
  }
}