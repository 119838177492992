/*----------  Global Contextuals  ----------*/

@each $class, $primary, $secondary in $global-contextuals
{
	.text#{$class}
	{
		@include text-color($primary);
	}

	.link#{$class}
	{
		@include text-color($primary, true);
	}

	.bg#{$class}
	{
		@include bg-color($primary);
	}

	.btn.btn-#{$class}
	{
		@include button-theme($secondary, $primary, transparent);

		&:hover, &:active, &:focus
		{
			@include button-theme($secondary, darken($primary, 5%), transparent);
		}
	}
}