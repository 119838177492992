/*----------  Typography Mixins  ----------*/

@mixin font-helvetica($font-size, $font-weight, $line-height, $color) 
{
	font-family: $font-helvetica;
	@include font($font-size, $font-weight, $line-height, $color);
}

@mixin font-helveticaOb($font-size, $font-weight, $line-height, $color) 
{
	font-family: $font-helveticaOb;
	@include font($font-size, $font-weight, $line-height, $color);
}

@mixin font-proxima($font-size, $font-weight, $line-height, $color) 
{
	font-family: $font-proxima;
	@include font($font-size, $font-weight, $line-height, $color);
}

@mixin font-trumpgothicprobolditalic($font-size, $font-weight, $line-height, $color) 
{
	font-family: $font-trumpgothicprobolditalic;
	@include font($font-size, $font-weight, $line-height, $color);
}