/*----------  Footer  ----------*/

footer
{
	.footer-container
	{
		.footer-inner
		{
			padding: 90px 0;
			@include media-breakpoint-down(sm)
			{
				padding: 45px 0;
			}
		}
		.copyright-wrapper
		{
			background: rgba($black-color, 0.2); 
			.copyright-container
			{
				p
				{
					text-align: center;
					padding: 22px 0;
					@include font-helvetica(rem-calc(14), $weight-regular, 1.5, $white-color);

					@include media-breakpoint-down(xs)
					{
						font-size: rem-calc(12);
					}
				}
			}
		}
	}
}