/*----------  Body  ----------*/

body
{
	@include preloader($white-color, $indigo, true);
	@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
}

.container-lg
{
	@include extend-container(1250px, 30);
}

.container-lg
{
	@include extend-container(1300px, 30);
}

.container-md
{
	@include extend-container(1140px, 30);
}

.text-lg
{
	font-size: rem-calc(32);

	@include media-breakpoint-down(md)
	{
		font-size: rem-calc(24);
	}

	@include media-breakpoint-down(xs)
	{
		font-size: rem-calc(18);
	}
}

.btn
{
	@include transition;
	border: 2px solid;
	@include font-s(rem-calc(60), $weight-regular, 1, inherit);
	width: 495px;
	padding: 15px 0;
	letter-spacing: 5px;
	border-radius: 7px;
	@include box-shadow(none);

	&:hover, &:active, &:focus
	{
		@include box-shadow(none);
	}

	@include media-breakpoint-down(md)
	{
		width: 300px;
		padding: 10px 0;
		font-size: rem-calc(45);
		letter-spacing: 3px;
	}
	@include media-breakpoint-down(xs)
	{
		border-radius: 5px;
		width: auto;
		padding: 10px 15px;
		font-size: rem-calc(30);
		letter-spacing: 1px;
	}
}

.btn-bordered
{
	background: transparent;
}

h1
{
	@include font-p(rem-calc(77), $weight-regular, 1.2, inherit);
	@include media-breakpoint-down(md)
	{
		font-size: rem-calc(60);
	}
	@include media-breakpoint-down(xs)
	{
		font-size: rem-calc(36);
	}
}

h2
{
	@include font-s(rem-calc(70), $weight-regular, 1.2, inherit);
	letter-spacing: 2px;
	margin-bottom: 40px;

	@include media-breakpoint-down(md)
	{
		font-size: rem-calc(45);
		margin-bottom: 25px;
	}

	@include media-breakpoint-down(xs)
	{
		font-size: rem-calc(32);
		margin-bottom: 15px;
	}
}

p
{
	@include font-helvetica(rem-calc(24), $weight-bold, 1.5, $brand-gray-01);
	text-align: left;
	margin-bottom: 40px;

	&:last-of-type
	{
		margin: 0;
	}

	@include media-breakpoint-down(md)
	{
		font-size: rem-calc(20);
	}

	@include media-breakpoint-down(xs)
	{
		font-size: rem-calc(14);
	}
}